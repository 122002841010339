
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
$main_1: #6533ff;


.logo-icon{
    width: 3rem;
    margin: 0.5rem 0 0.5rem 2rem;
    

}
.li-header{
    margin: 0 1.5rem;
    font-weight: 500;
    font-size: 1.1rem;
}
.container-Privacity{
    width: 80%;
    margin: 6rem auto;
    font-family: "Montserrat", sans-serif;
    background-color: #6533ff;
    padding: 1rem;
    h4{
        text-align: center;
        color: #ffffff;
        font-weight: 600;
        margin: 1.5rem;
        text-shadow: 0.1em 0.01em rgba(51, 51, 51, 0.247);
    }
    h6 {
        color: #ffffff;
       
    }
    p{
        padding: 1rem;
        color:#c4bf1a;
    }
}
//@media (max-width: 1200px) {
    
//}