@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
$main_1: #6533ff;
//font-family: 'Montserrat', sans-serif;
.container-fluid{
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.281)
}
.logo{
    width: 10.5rem;
    margin:0.5rem 3rem 0.5rem 2rem; ;

}
.li-header{
    margin: 0 1.5rem;
    font-weight: 500;
    font-size: 1.1rem;
}


@media (max-width: 1200px) {
    .li-header{
       font-size: 1.25rem;
    }
}