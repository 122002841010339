@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
$white: #fafaff;
$white_2: #f5fcff;
$black: #000;
$main_1: #6533ff;
$main_2: #ef2b43;
$main_3: #2fb1ea;
$main_4: #33e583;
$main_5: #ffd522;
$main_6: #78cc8f;
$main_ligth_1: #b19ffc;
$main_ligth_2: #f98598;
$main_ligth_3: #8cddf9;
$main_ligth_4: #7df9b2;
$main_ligth_5: #ffe473;
$main_dark_1: #09064f;
$main_dark_2: #751121;
$main_dark_3: #17617a;
$darkgreen: #16723d;
$main_dark_5: #7f5416;
$neutral: #c4bf1a;
$gray_0: #f7f7f7;
$gray_1: #ebebeb;
$gray_2: #a7a7a7;
$gray_3: #8f8f8f;
$transparent: transparent;
$statusBar: #541dff;
$backgroundgray: #f1efef;

body {
  position: relative;
  padding-bottom: 6rem;
  //min-height: 100vh !important;
  background-color: #6633ffe3 !important;
  font-family: "Varela Round", sans-serif !important;
  box-sizing: inherit;
}
.img-body {
  width: 30rem;
  margin: 10rem 1rem 15rem 1rem;
}
.principal-title {
  text-align: center;
  color: white;
  border-left: 15px solid #ffffff;
  margin-top: 10rem;
  padding-left: 1.5rem;
  text-shadow: 0.1em 0.01em rgba(51, 51, 51, 0.247);

  p {
    font-size: 20px;
    color: #c4bf1a;
    text-shadow: none;
    padding: 2rem;
  }
}

.cel {
  margin: 4rem auto 10rem;
  display: block;
  max-width: 80rem;
  border-radius: 150px;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.582));
}
.gif {
  width: 20rem;
  border-radius: 20px;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.582));
  margin: 6rem 6rem 6rem 7rem;
}
.title-r {
  text-align: center;
  color: white;
  margin-top: 10rem;
  padding-left: 1.5rem;
  //text-shadow: 0.1em 0.01em rgba(51, 51, 51, 0.397);
}
.list {
  font-size: 20px;
  color: #c4bf1a;
  margin-left: 5rem;
  padding: 1rem;
  line-height: 2;
}
.title-pasos {
  text-align: center;
  color: white;
  margin: 3rem 1rem;
  //padding-bottom: 2rem;
  transition: all 1s ease-out;
}
.title-pasos:hover {
  color: #c4bf1a;
  text-shadow: 0.1em 0.01em rgba(51, 51, 51, 0.247);
}

.pasos {
  margin: 4rem auto 10rem;
  display: block;
  max-width: 80rem;
  transition: all 1s ease-out;
}
.pasos:hover {
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.582));
}
.pasoM {
  display: none;
}

.servicios {
  margin: 4rem auto 10rem;
  display: block;
  max-width: 80rem;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.582));
}
.serviciosM {
  display: none;
}
section {
  width: 100%;
  //height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container-question {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto 7rem;
   
    padding: 0 1.5rem;
  }
  .acordion-item {
    background-color: #2fb1ea;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    padding: 1rem;
    box-shadow: 0.1em 0.01em rgba(51, 51, 51, 0.247);
  }
  .acordion-link {
    font-size: 1.6rem;
    color: #ffffff;
    text-decoration: none;
    background-color: #2fb1ea;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    .icon-question {
      width: 1.8rem;
      padding: 0.5rem;
    }
    .remove {
      display: none;
    }
  }
  .answer {
    max-height: 0;
    overflow: hidden;
    position: relative;
    background-color: #8cddf9;
    transition: max-height 650ms;
  }
  .answer::before {
    content: "";
    position: absolute;
    width: 0.6rem;
    height: 100%;
    background-color: #6633ffe3;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .answer p {
    font-size: 1.4rem;
    color: #6633ffe3;
    padding: 1rem;
  }
  .acordion-item:target .answer {
    max-height: 20rem;
  }
  .acordion-item:target .add {
    display: none;
  }
  .acordion-item:target .remove {
    display: block;
  }
}

@media (max-width: 1200px) {
  body {
    min-height: 200vh !important;
  }
  .img-body {
    width: 20rem;
    margin: 10rem 1rem 1rem 1rem;
  }
  .principal-title {
    text-align: center;
    color: white;
    border-left: none;
    border-bottom: 10px solid #ffffff;
    margin: 3rem 1rem;

    padding-bottom: 2rem;
    span {
      font-size: 20px;
      font-style: italic;
    }
  }
  .cel {
    margin: 4rem auto 4rem;
    display: block;
    max-width: 20rem;
    border-radius: 100px;
  }
  .gif {
    width: 15rem;
    margin: 4rem auto 4rem;
    display: block;
  }
  .list {
    font-size: 20px;
    color: #daacf5;
    margin: 2rem;
    padding: 1rem;
  }

  .pasoM {
    display: block;
    width: 20rem;
    margin: 4rem auto 4rem;
  }
  .pasos {
    display: none;
  }
  .servicios {
    display: none;
  }
  .serviciosM {
    margin: 4rem auto 2rem;
    display: block;
    max-width: 20rem;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.582));
    display: block;
  }
  .acordion-link {
    font-size: 1.2rem !important;
    padding: 0.5rem 0 !important;
  }
  .answer p {
    font-size: 1.2rem !important;
  }
}
