@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
$main_1: #6533ff;
.contain-footer {
  background-color: aqua;
  //margin-top: 5rem !important;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.cosa {
  position: relative;
  //  padding: 19px 36px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;

  border: none;
}
.cosa h6 {
  position: relative;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 5px;
  padding: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  z-index: 1;
  text-align: center;
}



.cosa .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  height: 400px;
  background: #6533ff;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.349);
  transition: 0.5s;
}

.cosa .liquid::after,
.cosa .liquid::before {
  content: "";
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-60%, -75%);
  background: #fff;
}

.cosa .liquid::before {
  border-radius: 40%;
  background: #9179f3bd;
  animation: animate 5s linear infinite;
}

.cosa .liquid::after {
  border-radius: 50%;
  background: rgba(86, 45, 102, 0.274);
  animation: animate 10s linear infinite;
}
.link-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social {
  width: 2rem;
  z-index: 1;
  position: relative;
  transition: all 0.2s;
}
.social:hover {
  filter: drop-shadow(1px 2px 10px rgba(110, 9, 114, 0.9));
}
@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

@media (max-width: 1200px) {
  
  .cosa h6 {
   
    letter-spacing: 0px;
    
  }
}